import { Button, Flex, Pagination, Select } from "@mantine/core";
import BaseBox from "components/Box/BaseBox";
import BaseContents from "components/Contents/BaseContents";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

import {
  PendingReasonsGet200ResponseRowsInner,
  TicketsGet200ResponseRowsInnerStepStatusEnum,
} from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { pendingReasons } from "api/pending/usePendingReasonsQuery";
import SearchBox, { SearchProps } from "components/SearchBox";
import { pageOptions } from "constants/pageOptions";
import {
  convertFilterToKorean,
  receptionStatusKeys,
  receptionStatusList,
} from "constants/receptionStatusList";
import { pendingReasonsSearchType } from "constants/searchType";
import { SortProps } from "pages/receptions/Reception/ReceptionTable";
import { useState } from "react";
import PendingReasonsTable from "./pendingReasonTable";
import ReasonRegisterForm from "./reasonRegisterForm";

const PendingReasons = Object.assign({}, "", {
  Content: BaseContents,
  Box: BaseBox,
  SearchBox: SearchBox,
});

const filteredSearchType = pendingReasonsSearchType
  .filter((type) => type.value)
  .map((type) => type.value);

function PendingReasonsPage() {
  const { openModal } = useModal();
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );
  const [filter, setFilter] = useState<keyof typeof receptionStatusList>("ALL");
  const [perPageRows, setPerPageRows] = useState(5); // code changes for ticket #564 by TBI
  const [sortColumns, setSortColumns] = useState<Map<string, SortProps>>(
    (new Map() as Map<string, SortProps>).set("createdAt", {
      columnKey: "createdAt",
      direction: undefined,
    })
  );

  const [search, setSearch] = useState<SearchProps>({
    search: "",
    searchFields: [],
    pageSize: "10",
  });

  const [page, setPage] = useState<number>(1);

  const { data } = useQuery(
    pendingReasons.get({
      query: [
        JSON.stringify({
          $and: {
            pendingType:
              filter === "ALL"
                ? [
                    TicketsGet200ResponseRowsInnerStepStatusEnum.PENDING,
                    TicketsGet200ResponseRowsInnerStepStatusEnum.DELIVERY_PENDING,
                    TicketsGet200ResponseRowsInnerStepStatusEnum.ACCEPT_PENDING,
                  ]
                : (receptionStatusList[filter] as string[]),
            $or: [
              {
                siteId: {
                  $eq: localStorage.getItem("siteId"),
                },
              },
              {
                siteId: {
                  $is: null,
                },
              },
            ],
          },
        }),
      ],
      page: page,
      pageSize: perPageRows,
      search: search.search,
      searchFields: search.searchFields.length
        ? search.searchFields
        : filteredSearchType,
      sort: "-id",
    })
  );

  return (
    <PendingReasons.Content
      title={
        <Flex justify={"space-between"}>
          {"지연사유 관리"}
          <Button
            bg={"blue"}
            onClick={() =>
              openModal(<ReasonRegisterForm />, "", "지연사유 추가", true)
            }
          >
            {"지연사유 추가"}
          </Button>
        </Flex>
      }
    >
      <Flex justify={"flex-end"} gap={5} mb={10}>
        {receptionStatusKeys
          .filter(
            (key) =>
              key === "ALL" ||
              key === "ACCEPT_PENDING" ||
              key === "PENDING" ||
              key === "DELIVERY_PENDING"
          )
          .map((name) => {
            return (
              <Button
                w={"8rem"}
                key={name}
                onClick={() => {
                  setPage(1);
                  setFilter(name);
                }}
                bg={filter === name ? "blue" : "rgb(211, 212, 214)"}
              >
                {convertFilterToKorean(name)}
              </Button>
            );
          })}
      </Flex>
      <PendingReasons.Box>
        <Flex justify={"space-between"} mb={20}>
          <Select
            placeholder="페이지당 개수"
            data={pageOptions}
            onChange={(e) => {
              setPage(1);
              setPerPageRows(Number(e));
            }}
          ></Select>

          <SearchBox
            searchType={pendingReasonsSearchType}
            setSearch={(searchedValue: any) => {
              setSearch(searchedValue);
              setPage(1);
            }}
          />
        </Flex>
        <PendingReasonsTable
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          sortColumns={sortColumns}
          setSortColumns={setSortColumns}
          pendingReasons={
            data?.data?.rows as PendingReasonsGet200ResponseRowsInner[]
          }
        />
        <Flex justify={"center"} mt={20}>
          <Pagination
            value={page}
            total={data?.data?.totalPages as number}
            onChange={(value) => setPage(value)}
          />
        </Flex>
      </PendingReasons.Box>
    </PendingReasons.Content>
  );
}

export default PendingReasonsPage;
