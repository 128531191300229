import { useMutation, useQueryClient } from "@tanstack/react-query";
// import BaseInstance from "instance/axios";
import {
  TicketApiTicketsPostRequest,
  TicketApiTicketsTicketIdAcceptPutRequest,
  TicketApiTicketsTicketIdAwaitingReceiptPutRequest,
  TicketApiTicketsTicketIdAwaitingStagePutRequest,
  TicketApiTicketsTicketIdCancelClosedAutoPutRequest,
  TicketApiTicketsTicketIdCancelFinishAutoPutRequest,
  TicketApiTicketsTicketIdClosedPutRequest,
  TicketApiTicketsTicketIdConfirmDonePutRequest,
  TicketApiTicketsTicketIdDeliverPutRequest,
  TicketApiTicketsTicketIdFinishAutoPutRequest,
  TicketApiTicketsTicketIdFinishProcessPutRequest,
  TicketApiTicketsTicketIdForceDeliverPutRequest,
  TicketApiTicketsTicketIdForceFinishPutRequest,
  TicketApiTicketsTicketIdParkingLocationHistoryGetRequest,
  TicketApiTicketsTicketIdPendAutoPutRequest,
  TicketApiTicketsTicketIdPutRequest,
  TicketApiTicketsTicketIdResumeAutoPutRequest,
  TicketApiTicketsTicketIdStartAutoPutRequest,
  TicketApiTicketsTicketIdStartProcessPutRequest,
  TicketApiTicketsTicketIdUpdateParkingLocationPutRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { TicketInstance } from "instance/axios";
import { mutateTickets } from "./useTicketsQuery";

type StartParams = TicketApiTicketsTicketIdStartAutoPutRequest;
type ForceFinishParams = TicketApiTicketsTicketIdForceFinishPutRequest;
type DoneParams = TicketApiTicketsTicketIdConfirmDonePutRequest;
type ResumeParams = TicketApiTicketsTicketIdResumeAutoPutRequest;
type UpdateParams = TicketApiTicketsTicketIdPutRequest;
type DeleteParams = TicketApiTicketsTicketIdConfirmDonePutRequest;
type ForceCloseParams = TicketApiTicketsTicketIdClosedPutRequest;
type ForceCloseCancelParams =
  TicketApiTicketsTicketIdCancelClosedAutoPutRequest;

const ALERT_MESSAGE = {
  awaitReceipt: "접수가 완료되었습니다.",
  start: "정비가 시작되었습니다.",
  forceFinish: "정비가 종료되었습니다.",
  forceClose: "정비가 마감되었습니다.",
  forceCloseCancel: "정비 마감이 취소되었습니다.",
  done: "정비완료 확인 알림톡이 전송되었습니다.",
  resume: "지연이 해소되었습니다.",
  deleteTicket: "접수건이 삭제되었습니다.",
  temporaryInProgress: "임시정비가 시작되었습니다.",
};

const useTicketMutation = () => {
  const queryClient = useQueryClient();

  // 정비 시작
  const startMutation = useMutation(
    (params: StartParams) =>
      mutateTickets.startAuto(params).mutationFn(params as StartParams | any),
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.start);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 임의 정비 종료
  const forceFinishMutation = useMutation(
    (params: ForceFinishParams) =>
      mutateTickets
        .forceFinish(params)
        .mutationFn(params as ForceFinishParams | any),
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.forceFinish);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 정비 마감
  const forceCloseMutation = useMutation(
    (params: ForceCloseParams) =>
      mutateTickets.close(params).mutationFn(params as ForceCloseParams | any),
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.forceClose);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 정비 마감 취소
  const forceCloseCancelMutation = useMutation(
    (params: ForceCloseCancelParams) =>
      mutateTickets
        .cancelClose(params)
        .mutationFn(params as ForceCloseCancelParams | any),
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.forceCloseCancel);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 완료 메시지 전송
  const doneMutation = useMutation(
    (params: DoneParams) =>
      mutateTickets.confirmDone(params).mutationFn(params as DoneParams | any),
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.done);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 지연 해소
  const resumeMutation = useMutation(
    (params: ResumeParams) => {
      return TicketInstance.ticketsTicketIdResumeAutoPut({
        ticketId: params.ticketId,
        ticketsTicketIdResumeAutoPutRequest: {
          useResumeNotification:
            params.ticketsTicketIdResumeAutoPutRequest?.useResumeNotification,
        },
      });
    },
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.resume);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 접수건 수정
  const updateMutation = useMutation(
    (params: UpdateParams) =>
      mutateTickets.update(params).mutationFn(params as UpdateParams | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 접수건 삭제
  const deleteMutation = useMutation(
    (params: DeleteParams) =>
      mutateTickets.delete(params).mutationFn(params as DeleteParams | any),
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.deleteTicket);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 출고
  const deliverMutation = useMutation(
    (params: TicketApiTicketsTicketIdDeliverPutRequest) =>
      mutateTickets
        .deliver(params)
        .mutationFn(params as TicketApiTicketsTicketIdDeliverPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 정비대기 확인
  const acceptMutation = useMutation(
    (params: TicketApiTicketsTicketIdAcceptPutRequest) =>
      mutateTickets
        .acceptPut(params)
        .mutationFn(params as TicketApiTicketsTicketIdAcceptPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 정비종료 취소
  const cancelFinishAutoMutation = useMutation(
    (params: TicketApiTicketsTicketIdCancelFinishAutoPutRequest) =>
      mutateTickets
        .cancelFinishAuto(params)
        .mutationFn(
          params as TicketApiTicketsTicketIdCancelFinishAutoPutRequest | any
        ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );
  // 접수건 정비 종료 또는 작업 단계 완료
  const finishAutoMutation = useMutation(
    (params: TicketApiTicketsTicketIdFinishAutoPutRequest) =>
      mutateTickets
        .finishAuto(params)
        .mutationFn(
          params as TicketApiTicketsTicketIdFinishAutoPutRequest | any
        ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );
  // 상세 작업 완료
  const finishProcessMutation = useMutation(
    (params: TicketApiTicketsTicketIdFinishProcessPutRequest) =>
      mutateTickets
        .finishProcess(params)
        .mutationFn(
          params as TicketApiTicketsTicketIdFinishProcessPutRequest | any
        ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 강제출고
  const forceDeliverMutation = useMutation(
    (params: TicketApiTicketsTicketIdForceDeliverPutRequest) =>
      mutateTickets
        .forceDeliver(params)
        .mutationFn(
          params as TicketApiTicketsTicketIdForceDeliverPutRequest | any
        ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );
  // 주차위치 업데이트 내역조회
  const parkingLocationHistoryMutation = useMutation(
    (params: TicketApiTicketsTicketIdParkingLocationHistoryGetRequest) =>
      mutateTickets
        .parkingLocationHistory(params)
        .mutationFn(
          params as
            | TicketApiTicketsTicketIdParkingLocationHistoryGetRequest
            | any
        ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );
  // 지연사유 발생
  const pendAutoMutation = useMutation(
    (params: TicketApiTicketsTicketIdPendAutoPutRequest) =>
      mutateTickets
        .pendAuto(params)
        .mutationFn(params as TicketApiTicketsTicketIdPendAutoPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );
  // 상세작업 시작
  const startProcessMutation = useMutation(
    (params: TicketApiTicketsTicketIdStartProcessPutRequest) =>
      mutateTickets
        .startProcess(params)
        .mutationFn(
          params as TicketApiTicketsTicketIdStartProcessPutRequest | any
        ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );
  // 주차위치 업데이트
  const updateParkingLocationMutation = useMutation(
    (params: TicketApiTicketsTicketIdUpdateParkingLocationPutRequest) =>
      mutateTickets
        .parkingLocationHistory(params)
        .mutationFn(
          params as
            | TicketApiTicketsTicketIdUpdateParkingLocationPutRequest
            | any
        ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );
  // 티켓 접수
  const createMutation = useMutation(
    (params: TicketApiTicketsPostRequest) =>
      mutateTickets
        .create(params)
        .mutationFn(params as TicketApiTicketsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  const awaitReceiptMutation = useMutation(
    (params: TicketApiTicketsTicketIdAwaitingReceiptPutRequest) =>
      mutateTickets
        .awaitingReceipt(params)
        .mutationFn(
          params as TicketApiTicketsTicketIdAwaitingReceiptPutRequest | any
        ),
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.awaitReceipt);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 임시 정비 시작
  const temporaryStageInProgressMutate = useMutation(
    (params: TicketApiTicketsTicketIdAwaitingStagePutRequest) =>
      mutateTickets
        .temporaryStageInProgress(params)
        .mutationFn(
          params as TicketApiTicketsTicketIdAwaitingStagePutRequest | any
        ),
    {
      onSuccess: () => {
        alert(ALERT_MESSAGE.temporaryInProgress);
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  return {
    startMutate: startMutation.mutate,
    forceFinishMutate: forceFinishMutation.mutate,
    forceCloseMutate: forceCloseMutation.mutate,
    forceCloseCancelMutate: forceCloseCancelMutation.mutate,
    doneMutate: doneMutation.mutate,
    resumeMutate: resumeMutation.mutate,
    updateMutate: updateMutation.mutate,
    deleteMutate: deleteMutation.mutate,
    deliverMutate: deliverMutation.mutate,
    forceDeliverMutate: forceDeliverMutation.mutate,
    acceptMutate: acceptMutation.mutate,
    cancelFinishAutoMutate: cancelFinishAutoMutation.mutate,
    finishAutoMutate: finishAutoMutation.mutate,
    finishProcessMutate: finishProcessMutation.mutate,
    parkingLocationHistoryMutate: parkingLocationHistoryMutation.mutate,
    pendAutoMutate: pendAutoMutation.mutate,
    startProcessMutate: startProcessMutation.mutate,
    updateParkingLocationMutate: updateParkingLocationMutation.mutate,
    createMutate: createMutation.mutate,
    awaitReceiptMutate: awaitReceiptMutation.mutate,
    temporaryStageInProgressMutate: temporaryStageInProgressMutate.mutate,
  };
};

export default useTicketMutation;
