import { Button, Flex, Group } from "@mantine/core";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { Calendar, CalendarDate } from "pages/receptions/Reception/Calendar";

// dayjs에 isoWeek 플러그인을 추가하여 주의 시작일을 월요일로 설정
dayjs.extend(isoWeek);

interface CalendarDatePickerProps {
  date: {
    selectedDateRange: CalendarDate;
    setSelectedDateRange: (date: CalendarDate) => void;
  };
}

// CustomDatePicker 컴포넌트는 사용자가 날짜 범위를 선택하거나, 오늘, 이번 주, 이번 달과 같은 프리셋 버튼을 통해 빠르게 날짜를 설정할 수 있는 Popover 기반의 UI를 제공합니다.
export const CalendarDatePicker = ({ date }: CalendarDatePickerProps) => {
  const { closeModal } = useModal();
  const { selectedDateRange, setSelectedDateRange } = date;

  const handleDateChange = (dateRange: [Date | null, Date | null]) => {
    setSelectedDateRange(dateRange);
    // 양쪽 날짜가 모두 선택되었을 때 팝오버를 닫음
    if (dateRange[0] && dateRange[1]) {
      closeModal(dateRange);
    }
  };

  const setToday = () => {
    const today = new Date();
    closeModal([today, today]);
  };

  const setThisWeek = () => {
    const startOfWeek = dayjs().startOf("isoWeek").toDate(); // isoWeek로 주의 시작일을 월요일로 설정
    const endOfWeek = dayjs().endOf("isoWeek").toDate(); // isoWeek로 주의 종료일을 일요일로 설정
    closeModal([startOfWeek, endOfWeek]);
  };

  const setThisMonth = () => {
    const startOfMonth = dayjs().startOf("month").toDate();
    const endOfMonth = dayjs().endOf("month").toDate();
    closeModal([startOfMonth, endOfMonth]);
  };

  const setBeforeThreeMonth = () => {
    const startOfPeriod = dayjs().subtract(3, "month").toDate(); // 3개월 전의 날짜를 시작일로 설정
    const endOfPeriod = dayjs().toDate(); // 오늘 날짜를 종료일로 설정
    closeModal([startOfPeriod, endOfPeriod]);
  };

  return (
    <Flex direction="column" gap="sm">
      <Group position="center" mt="md">
        <Button compact variant="outline" onClick={setToday}>
          오늘
        </Button>
        <Button compact variant="outline" onClick={setThisWeek}>
          이번 주
        </Button>
        <Button compact variant="outline" onClick={setThisMonth}>
          이번 달
        </Button>
        <Button compact variant="outline" onClick={setBeforeThreeMonth}>
          최근 3개월
        </Button>
      </Group>
      <Calendar
        type="range"
        dates={selectedDateRange}
        onChange={handleDateChange}
        size="md"
      />
    </Flex>
  );
};
