import { Box, Center, NavLink, Text } from "@mantine/core";
import { IconCheckupList, IconHome2, IconLayoutBoard, IconNotes, IconSettings, IconSettingsPause, IconSettingsSearch, IconUserEdit, IconUserSearch } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { users } from "api/users/useUsersQuery";
import { ReactComponent as DashboardLogo } from "images/svg/Dashboard_logo.svg";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";

interface MenuListType {
    id: number;
    parentId: number | null;
    path: string;
    name: JSX.Element | string;
    icon: JSX.Element;
    role?: string[];
}

const menuList: MenuListType[] = [
    {
        id: 1,
        parentId: null,
        path: "/dashboard",
        name: <Text fz="md" c="gray">Dashboards</Text>,
        icon: <IconLayoutBoard color="gray" />,
        role: ["ADVISER", "TECHNICIAN", "ADMIN"]
    },
    {
        id: 2,
        parentId: null,
        path: "/reception",
        name: <Text fz="md" c="gray">접수현황</Text>,
        icon: <IconNotes color="gray" />,
        role: ["ADVISER", "TECHNICIAN", "ADMIN"]
    },
    {
        id: 3,
        parentId: null,
        path: "/receptionArchive",
        name: <Text fz="md" c="gray">마감현황</Text>,
        icon: <IconCheckupList color="gray" />,
        role: ["ADVISER", "TECHNICIAN", "ADMIN"]
    },
    {
        id: 5,
        parentId: null,
        path: "/informationManagement",
        name: <Text fz="md" c="gray">내 정보 관리</Text>,
        icon: <IconUserEdit color="gray" />,
    },
    {
        id: 6,
        parentId: null,
        path: "#",
        name: <Text fz="md" c="gray">설정</Text>,
        icon: <IconSettings color="gray" />,
        role: ["ADMIN", "DEALER", "MBK"]
    },
    {
        id: 4,
        parentId: 6,
        path: "/users",
        name: <Text fz="md" c="gray">사용자 관리</Text>,
        icon: <IconUserSearch color="gray" />,
        role: ["ADMIN"]
    },
    {
        id: 7,
        parentId: 6,
        path: "/siteConfig",
        name: <Text fz="md" c="gray">센터 관리</Text>,
        icon: <IconHome2 color="gray" />,
        role: ["ADMIN"]
    },
    {
        id: 8,
        parentId: 6,
        path: "/pendingReason",
        name: <Text fz="md" c="gray">지연사유 관리</Text>,
        icon: <IconSettingsPause color="gray" />,
        role: ["ADMIN"]
    },
    // {
    //     id: 9,
    //     parentId: 6,
    //     path: "/processType",
    //     name: <Text fz="md" c="gray">상세정비 관리</Text>,
    //     icon: <IconSettingsCog color="gray" />,
    //     role: ["ADMIN"]
    // },
    {
        id: 10,
        parentId: 6,
        path: "/dataManagement",
        name: <Text fz="md" c="gray">데이터 관리</Text>,
        icon: <IconSettingsSearch color="gray" />,
        role: ["ADMIN", "DEALER", "MBK"]
    }
];

// const roleList = [
//     "ADMIN",
//     "ADVISOR",
//     "TECHNICIAN",
// ]

// 스타일 상수 정의
const navLinkStyles = (isActive: boolean) => (theme: { colors: { gray: any[]; }; }) => ({
    root: {
        '&:hover': {
            backgroundColor: theme.colors.gray[3],
        },
        backgroundColor: isActive ? theme.colors.gray[3] : 'transparent',
    }
});

const generateMenu = (menuList: MenuListType[], parentId: number | null = null, navigate: NavigateFunction, location: Location<any>, role: string) => {
    return menuList
        .filter(menu => menu.parentId === parentId)
        .filter(menu => !menu.role || menu.role.includes(role))
        .map(menu => {
            const children = generateMenu(menuList, menu.id, navigate, location, role);
            const isActive = location.pathname === menu.path;

            if (children.length > 0) {
                return (
                    <NavLink
                        key={menu.id}
                        label={menu.name}
                        icon={menu.icon}
                        c="gray"
                        styles={navLinkStyles(isActive)}
                        p="1rem"
                        defaultOpened
                    >
                        {children}
                    </NavLink>
                );
            }
            return (
                <NavLink
                    key={menu.id}
                    label={menu.name}
                    icon={menu.icon}
                    c="gray"
                    onClick={() => navigate(menu.path)}
                    styles={navLinkStyles(isActive)}
                    p="1rem"
                />
            );
        });
};

export const SideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { data } = useQuery(users.who({}))
    const role = data?.data?.role ?? "";

    return (
        <Box w={210} pos="absolute" bg="black" h="100%">
            <Center p="2rem">
                <DashboardLogo style={{ cursor: "pointer" }} onClick={() => navigate('/dashboard')} />
            </Center>
            {generateMenu(menuList, null, navigate, location, role)}
        </Box>
    );
};
