export const StepStatus = {
  TEMPORARY_RECEIPTED: "접수대기",
  RECEIPTED: "접수완료",
  ACCEPT_PENDING: "점검지연",
  WAITING: "점검시작",
  PENDING: "점검지연",
  TEMPORARY_STAGE_IN_PROGRESS: "임시 정비진행",
  STAGE_IN_PROGRESS: "정비진행",
  IN_PROGRESS_PENDING: "정비지연",
  STAGE_WAITING: "정비단계 대기",
  WAITING_PENDING: "정비단계 시작 전 지연",
  PROCESSING: "상세정비",
  PROCESS_PENDING: "상세정비 지연",
  DONE: "정비완료",
  DONE_CONFIRMED: "정비완료 확인",
  DELIVERY_PENDING: "출고지연",
  CLOSED: "정비마감",
  DELIVERED: "출고완료",
};
