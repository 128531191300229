import { Button, Flex, MediaQuery, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { handleKeyDown } from "utils/event";

export interface SearchProps {
  search: string;
  searchFields: string[];
  pageSize: string;
}

interface SetSearchProps {
  searchType: { label: string; value: string }[];
  setSearch: any;
}

const SearchBox = ({ searchType, setSearch }: SetSearchProps) => {
  const form = useForm({
    initialValues: {
      searchFields: "",
      search: "",
      pageSize: "10",
    },
  });

  const handleSearch = () => {
    setSearch((prev: any) => ({
      ...prev,
      pageSize: form.values.pageSize,
      search: form.values.search.trim(),
      searchFields: form.values.searchFields
        ? [form.values.searchFields]
        : prev.searchFields,
    }));
  };

  return (
    <>
      <MediaQuery styles={{ display: "none" }} smallerThan="sm">
        <Flex gap="md" justify="center" align="center">
          <Select
            data={searchType}
            placeholder="통합 검색"
            {...form.getInputProps("searchFields")}
          />
          <TextInput
            placeholder="검색어를 입력하세요"
            {...form.getInputProps("search")}
            w="100%"
            onKeyDown={(e) => handleKeyDown(e, handleSearch)}
          />
          <Button onClick={handleSearch}>검색</Button>
        </Flex>
      </MediaQuery>
      <MediaQuery styles={{ display: "none" }} largerThan="sm">
        <Flex gap="md" justify="center" align="center" direction="row">
          <Select
            data={searchType}
            placeholder="통합 검색"
            {...form.getInputProps("searchFields")}
            w="60%"
          />
          <TextInput
            placeholder="검색어를 입력하세요"
            {...form.getInputProps("search")}
            w="100%"
            onKeyDown={(e) => handleKeyDown(e, handleSearch)}
          />
          <Button onClick={handleSearch} w="30%">
            검색
          </Button>
        </Flex>
      </MediaQuery>
    </>
  );
};

export default SearchBox;
