import styled from "@emotion/styled";
import { Button, PasswordInput, Select, Text, TextInput } from "@mantine/core";
import { useForm } from '@mantine/form';
import { AuthSignupPost201Response, AuthSignupPostRequestRoleEnum, MasterApiUsersUserIdPutRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutateUser } from "api/users/useUsersQuery";
import { Container } from "components/BaseManagement/Container";
import { ContentBox } from "components/BaseManagement/ContentBox";
import { Row } from "components/BaseManagement/Row";
import { TitleBox } from "components/BaseManagement/TitleBox";
import { roles } from "constants/roles";
import { UserInstance } from "instance/axios";
import decodeToken from "utils/decodeToken";
import { authEnum } from "../../../config/auth";

const ManageMent = Object.assign({}, "", {
  Container: Container,
  Row: Row,
  TitleBox: TitleBox,
  ContentBox: ContentBox,
});

function InformationManagement({ userData }: { userData: AuthSignupPost201Response | undefined }) {


  const workbayList: { value: string; label: string }[] = [
    { value: '1', label: "워크베이1" },
  ];

  const queryClient = useQueryClient();
  const { mutate: userIdPut } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      mutateUser.update(params).mutationFn(params as MasterApiUsersUserIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      }
    }
  )

  const form = useForm({
    initialValues: {
      email: userData?.email,
      password: userData?.password,
      confirmPassword: userData?.password,
      name: userData?.name,
      mobile: userData?.mobile,
      exposedMobile: userData?.exposedMobile,
      role: userData?.role,
      defaultWorkbayId: userData?.defaultWorkbayId,
    }
  })

  const validation = () => {
    if (
      form.values.password !=
      form.values.confirmPassword
    ) {
      alert("패스워드와 패스워드 확인이 일치하지 않습니다.")
      return false;
    } else if (
      form.values.email != userData?.email && !form.values.password
    ) {
      alert("이메일과 패스워드를 입력해주세요.");
      return false;
    } else if (
      !form.values.password || !form.values.confirmPassword
    ) {
      alert("패스워드와 패스워드 확인을 입력해주세요.");
      return false;
    } else {
      return true;
    }
  };
  const onSubmit = async () => {
    if (validation()) {

      try {
        userIdPut({
          userId: userData?.id as number,
          authSignupPostRequest: {
            name: form.values.name as string,
            email: form.values.email as string,
            password: form.values.password as string,
            mobile: form.values.mobile,
            exposedMobile: form.values.exposedMobile,
            role: form.values.role as AuthSignupPostRequestRoleEnum,
            siteId: localStorage.getItem('siteId') ? Number(localStorage.getItem('siteId')) : 1,
            defaultWorkbayId: form.values.defaultWorkbayId,
          }
        }, {
          onSuccess: async () => {
            const identifier = form.values.email !== undefined ? form.values.email : userData?.email;

            localStorage.removeItem(authEnum.authToken);
            localStorage.removeItem('siteId');
            localStorage.removeItem('role');

            await UserInstance.authLoginPost({
              authLoginPostRequest: {
                identifier: identifier as string,
                password: form.values.password
              }
            }, {
              headers: {
                'Authorization': undefined
              }
            }).then(({ data }: any) => {
              localStorage.setItem(authEnum.authToken, data.token);
              const userInfo = decodeToken(data.token);
              localStorage.setItem('siteId', userInfo.user.siteId);
            });
            alert("사용자 정보가 수정되었습니다.")
          }
        })
      } catch (error) {
        alert("사용자 정보 수정이 실패하였습니다. 다시 시도해주세요.")
      }
    }
  };

  return (
    <>
      <ManageMent.Container>
        <ManageMent.Row
          borderTop="1px solid #babeca"
        >
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >사용자 아이디</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <TextInput
              ml="15px"
              w="18rem"
              defaultValue={userData?.email}
              {...form.getInputProps('email')}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        <ManageMent.Row>
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >패스워드</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <PasswordInput
              ml="15px"
              w="18rem"
              defaultValue={userData?.password}
              {...form.getInputProps('password')}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        <ManageMent.Row>
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >패스워드 확인</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <PasswordInput
              ml="15px"
              w="18rem"
              defaultValue={userData?.password}
              {...form.getInputProps('confirmPassword')}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        <ManageMent.Row>
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >이름</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <TextInput
              defaultValue={userData?.name}
              ml="15px"
              w="18rem"
              {...form.getInputProps('name')}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        <ManageMent.Row>
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >핸드폰 번호</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <TextInput
              defaultValue={userData?.mobile}
              ml="15px"
              w="18rem"
              {...form.getInputProps('mobile')}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        <ManageMent.Row>
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >노출가능한<br />핸드폰 번호</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <TextInput
              ml="15px"
              w="18rem"
              {...form.getInputProps('exposedMobile')}
              defaultValue={userData?.exposedMobile}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        <ManageMent.Row>
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >포지션</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <Select
              disabled
              data={roles}
              ml="15px"
              w="18rem"
              {...form.getInputProps('role')}
              value={userData?.role}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        {/* <ManageMent.Row>
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >워크베이</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <Select
              data={workbayList}
              ml="15px"
              w="18rem"
              {...form.getInputProps('defaultWorkbayId')}
              value={userData?.defaultWorkbayId?.toString()}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row> */}
        <ButtonContent>
          <Button
            size="md"
            color="gray"
          >
            취소
          </Button>
          <Button
            size="md"
            ml="sm"
            onClick={onSubmit}
          >
            수정
          </Button>
        </ButtonContent>
      </ManageMent.Container>
    </>
  );
}

export default InformationManagement;

const ButtonContent = styled.div`
  display : flex;
  justify-content : center;
  margin-top : 2rem;
  height: 4rem;
`