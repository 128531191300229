import { Button, Checkbox, Flex, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  PendingReasonsGet200ResponseRowsInner,
  TicketsGet200ResponseRowsInner,
  TicketsTicketIdPendAutoPutRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import useTicketMutation from "api/tickets/useTicketStatusChange";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

const ReceptionsCompleteModal = ({
  formatterProps,
  pendingReasons,
}: {
  formatterProps: TicketsGet200ResponseRowsInner;
  pendingReasons: PendingReasonsGet200ResponseRowsInner[];
}) => {
  const formData = useForm<TicketsTicketIdPendAutoPutRequest>({
    initialValues: {
      pendingReasonId: 0,
      message: "",
      usePendingNotification: false,
    },
  });

  const { closeModal } = useModal();

  const { pendAutoMutate } = useTicketMutation();

  const onSubmit = async () => {
    try {
      pendAutoMutate(
        {
          ticketId: formatterProps.id,
          ticketsTicketIdPendAutoPutRequest: {
            pendingReasonId: formData.values.pendingReasonId,
            message: formData.values.message ?? undefined,
            usePendingNotification: formData.values.usePendingNotification,
            pendingType:
              formatterProps.stepStatus === "WAITING"
                ? "ACCEPT_PENDING"
                : "PENDING", // ACCEPT_PENDING으로 보내면 정비대기일 때 지연, PENDING으로 보내면 접수완료일 때 지연
          },
        },
        {
          onSuccess: () => {
            alert("점검이 지연되었습니다.");
            closeModal({});
          },
        }
      );
    } catch (error) {
      alert("Error! please try again");
    }
  };

  return (
    <Flex direction="column" w="30rem" gap="md">
      <Select
        label="지연사유"
        defaultValue={formatterProps?.shippingInfo?.type}
        data={pendingReasons.map((reason) => {
          return {
            value: reason.id.toString(), // value를 string으로 변환
            label: reason.name,
          };
        })}
        withinPortal
        {...formData.getInputProps("pendingReasonId")}
      />
      <Flex gap="md" justify="space-between" align="center">
        <Checkbox
          disabled={!formatterProps?.usesNotification}
          label="알림톡 사용"
          {...formData.getInputProps("usePendingNotification")}
        />
        <Flex gap="md">
          <Button color="gray" onClick={closeModal}>
            취소
          </Button>
          <Button
            onClick={onSubmit}
            disabled={!formData.values.pendingReasonId}
          >
            점검지연
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReceptionsCompleteModal;
