import { Button, Checkbox, Flex, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { TicketsGetRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import useTicketMutation from "api/tickets/useTicketStatusChange";
import { users } from "api/users/useUsersQuery";
import { Confirm } from "components/Confirm";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

// 차량번호 유효성 검사 패턴
// const pattern = /^[0-9]{2,3}[가-힣]{1}[0-9]{4}$/;

interface RegisterFormProps {
  formatterProps?: any;
}
const RegisterForm = (params: RegisterFormProps) => {
  const { formatterProps } = params;

  const formData = useForm<TicketsGetRequest>({
    initialValues: {
      plateCode: formatterProps?.plateCode ?? "",
      customerName: formatterProps?.customerName ?? "",
      mobile: formatterProps?.mobile ?? "",
      siteId: Number(localStorage.getItem("siteId")),
      // vinCode, context, carModel, usesNotification, parkingLocation 은 mutate할 때 검증해서 undefined로 보낼 것. 기본값은 ""으로 한다.
      vinCode: formatterProps?.vinCode ?? "",
      context: formatterProps?.context ?? "",
      carModel: formatterProps?.carModel ?? "",
      usesNotification: formatterProps?.usesNotification ?? true,
      parkingLocation: formatterProps?.parkingLocation ?? "",
    }
  });

  const { openModal, closeModal } = useModal();

  const { data } = useQuery(users.who({}));
  const { awaitReceiptMutate, createMutate, updateMutate } = useTicketMutation();

  const loginUserId = data?.data.id;
  const onSubmit = async () => {
    const plateCode = formData.values.plateCode;

    if (!(plateCode.length > 0 && formData.values.customerName.length > 0 && formData.values.mobile.length > 0)) {
      alert("필수 항목들을 입력해주세요.");
      return;
    }

    try {
      openModal(
        <Confirm
          message={`${formData.values.usesNotification ? "접수건이 등록되면, 고객에게 알림톡이 전송됩니다.\n" : ""}접수건을 등록하시겠습니까?`}
          yesCallback={() => {
            // 엑셀접수
            formatterProps && formatterProps?.stepStatus === "TEMPORARY_RECEIPTED" ?
              updateMutate({
                ticketId: formatterProps?.id,
                ticketsTicketIdDeleteRequest: {
                  plateCode: plateCode,
                  customerName: formData.values.customerName,
                  mobile: formData.values.mobile,
                  siteId: Number(localStorage.getItem("siteId")),
                  vinCode: formData.values.vinCode || undefined,
                  context: formData.values.context || undefined,
                  carModel: formData.values.carModel || undefined,
                  usesNotification: formData.values.usesNotification ?? false,
                  parkingLocation: formData.values.parkingLocation || undefined,
                  incomingAt: new Date().toISOString(),
                  assignedAdviserId: loginUserId
                }
              }, {
                onSuccess: () => {
                  awaitReceiptMutate({
                    ticketId: formatterProps?.id
                  }, {
                    onSuccess: () => {
                      closeModal({});
                    }
                  })
                }
              })
              :
              // 신규접수
              createMutate({
                ticketsGetRequest: {
                  plateCode: plateCode,
                  customerName: formData.values.customerName,
                  mobile: formData.values.mobile,
                  siteId: Number(localStorage.getItem("siteId")),
                  vinCode: formData.values.vinCode || undefined,
                  context: formData.values.context || undefined,
                  carModel: formData.values.carModel || undefined,
                  usesNotification: formData.values.usesNotification ?? false,
                  parkingLocation: formData.values.parkingLocation || undefined,
                  stepStatus: "RECEIPTED",
                  incomingAt: new Date().toISOString(),
                  assignedAdviserId: loginUserId
                }
              }, {
                onSuccess: () => {
                  alert('접수되었습니다.');
                  closeModal({});
                }
              })
          }}
          noCallback={() => { }}
          commonCallback={() => closeModal({})}
        />, null, "접수 확인");
    } catch (error) {
      alert("Error! please try again");
      console.log("Ticket Create Error ::: ", error);
    }
  }

  return (
    <Flex w="30rem" direction="column" gap="1rem">
      <TextInput
        withAsterisk
        autoFocus
        label="차량번호"
        maxLength={10}
        defaultValue={formatterProps?.plateCode}
        placeholder="예: 00가0000"
        {...formData.getInputProps("plateCode")}
      />
      <TextInput
        label="모델"
        defaultValue={formatterProps?.carModel}
        placeholder="예: E200"
        {...formData.getInputProps("carModel")}
      />
      <TextInput
        label="VIN"
        defaultValue={formatterProps?.vinCode}
        placeholder="예: XXXXX41JXMN109XXX"
        {...formData.getInputProps("vinCode")}
      />
      <Flex w="100%" gap="md">
        <TextInput
          withAsterisk
          w="50%"
          label="고객명"
          defaultValue={formatterProps?.customerName}
          placeholder="예: 홍길동"
          {...formData.getInputProps("customerName")}
        />
        <TextInput
          withAsterisk
          w="50%"
          label="전화번호"
          defaultValue={formatterProps?.mobile}
          placeholder="예: 010-1234-5678"
          {...formData.getInputProps("mobile")}
        />
      </Flex>
      <TextInput
        label="주차 위치"
        defaultValue={formatterProps?.parkingLocation}
        placeholder="주차 위치 입력"
        {...formData.getInputProps("parkingLocation")}
      />
      <Textarea
        label="비고"
        defaultValue={formatterProps?.context}
        placeholder="필요한 추가 정보 입력"
        {...formData.getInputProps("context")}
      />
      <Flex justify="space-between" align="center">
        <Checkbox
          defaultChecked={formatterProps?.usesNotification ?? true}
          label="알림톡 사용"
          {...formData.getInputProps("usesNotification")}
        />
        <Flex gap="md">
          <Button color="gray" onClick={closeModal}>
            취소
          </Button>
          <Button onClick={onSubmit}>
            신규 접수
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
};

export default RegisterForm;
